import { Radio, Input, Divider, Button, Space, Spin, Select } from "antd"

import {
  DeleteOutlined,
} from '@ant-design/icons';
import { cloneDeep } from "lodash";
import { useState } from "react";


const Index = (props) => {
  const { value = [], onChange, onAiMake } = props
  const [loading, setLoading] = useState(false)
  console.log("valuevaluevalue", value)
  return (
    <Spin spinning={loading}>
      {
        value?.map((v, k) => (
          <div key={k} style={{ marginBottom: 32 }}>
            <Divider style={{ marginTop: 4 }}>
              <Input placeholder="单词" onChange={(e) => {
                const cpV = cloneDeep(value)
                cpV[k].word = e.target.value
                onChange(cpV)
              }} allowClear value={v.word} />
              &nbsp;
              <Button size="small" icon={<DeleteOutlined />} onClick={() => {
                const cpV = cloneDeep(value)
                cpV.splice(k, 1)
                onChange(cpV)
              }} danger></Button>
            </Divider>
            <div style={{
              display: "flex",
              alignItems: "center",
              margin: "8px 0"
            }}>
              <div style={{ width: 38, flexShrink: 0 }}>词性:</div>
              <Input onChange={(e) => {
                const cpV = cloneDeep(value)
                cpV[k].pos = e.target.value
                onChange(cpV)
              }} allowClear value={v.pos} />
            </div>
            <div style={{
              display: "flex",
              alignItems: "center",
              margin: "8px 0"
            }}>
              <div style={{ width: 58, flexShrink: 0 }}>定义:</div>
              <Input.TextArea onChange={(e) => {
                const cpV = cloneDeep(value)
                cpV[k].definition = e.target.value
                onChange(cpV)
              }} allowClear value={v.definition} />
            </div>
            <div style={{
              display: "flex",
              alignItems: "center",
              margin: "8px 0"
            }}>
              <div style={{ width: 58, flexShrink: 0 }}>同义词:</div>
              <Select
                mode="tags"
                style={{ width: '100%' }}
                placeholder="同义词"
                value={v.synonyms}
                onChange={e => {
                  const cpV = cloneDeep(value)
                  cpV[k].synonyms = e
                  onChange(cpV)
                }}
              />
            </div>
            <div style={{
              display: "flex",
              alignItems: "center",
              margin: "8px 0"
            }}>
              <div style={{ width: 58, flexShrink: 0 }}>反义词:</div>
              <Select
                mode="tags"
                style={{ width: '100%' }}
                placeholder="反义词"
                value={v.antonyms}
                onChange={e => {
                  const cpV = cloneDeep(value)
                  cpV[k].antonyms = e
                  onChange(cpV)
                }}
              />
            </div>
            <div style={{
              display: "flex",
              alignItems: "center",
              margin: "8px 0"
            }}>
              <div style={{ width: 58, flexShrink: 0 }}>引文:</div>
              <Select
                mode="tags"
                style={{ width: '100%' }}
                placeholder="引文"
                value={v.ctx}
                onChange={e => {
                  const cpV = cloneDeep(value)
                  cpV[k].ctx = e
                  onChange(cpV)
                }}
              />
            </div>
          </div>
        ))
      }
      <Space style={{ width: "100%" }} direction="vertical">
        <Button style={{ width: "100%" }} onClick={() => {
          const cpV = cloneDeep(value)
          cpV.push({
            "word": "",
            "pos": "",
            "synonyms": [],
            "antonyms": [],
            "definition": [],
            "ctx": []
          })
          onChange(cpV)
        }} type="primary">添加</Button>
        <Button style={{ width: "100%" }} type="dashed" onClick={async () => {
          setLoading(true)
          await onAiMake()
          setLoading(false)
        }}>Ai生成</Button>
      </Space>
    </Spin>
  )
}

export default Index