import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import http from '../../lib/http'
import * as apis from '../../lib/api'
import { Table, Input, message, Button, Form, Modal, InputNumber } from 'antd';
import { cloneDeep } from "lodash"
import { render } from "@testing-library/react";
import moment from "moment";


const Index = () => {
    const [loading, setLoading] = useState(false)
    const [response, setResponse] = useState({ total: 0, list: [] })
    const [update, setUpdate] = useState(new Date().getTime())
    const [tempTxt, setTempTxt] = useState('')
    const [open, setOpen] = useState(false)
    const n = useNavigate()
    const [search, setSearch] = useState({
        page: 1,
        page_size: 20
    })
    const curData = useRef()
    const [form2] = Form.useForm()

    const loadData = async (s) => {
        try {
            let data = await http.post(`${apis.USER_LIST}`, s)
            setResponse(data)
        } catch (error) {

        }
    }

    const updateContent = async (obj) => {
        try {
            await http.post(apis.TEMPLATE_UPDATE, obj)
        } catch (error) {

        }
    }

    useEffect(() => {
        setLoading(true)

        loadData(search).then(() => {
            setLoading(false)
        })

        let onresize = () => {
            setUpdate(new Date().getTime())
        }

        window.addEventListener("resize", onresize)
        return () => {
            window.removeEventListener("resize", onresize)
        }

        // eslint-disable-next-line
    }, [])


    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            fixed: 'left',
            width: 50
        },
        {
            title: 'vip信息',
            dataIndex: 'sub_user',
            width: 80,
            render: (r) => {
                if (!r.id) {
                    return "未开通vip"
                }
                return `vip到期时间：${moment(r.expired_at).format("YYYY-MM-DD HH:mm:ss")}`
            }
        },
        {
            title: '邮箱',
            dataIndex: 'email',
            width: 80
        },
        {
            title: '创建时间',
            dataIndex: 'created_at',
            width: 80,
            render: (v) => (
                <>
                    {moment(v).format("YYYY-MM-DD HH:mm:ss")}
                </>
            )
        },
        {
            title: '操作',
            dataIndex: 'ac',
            fixed: 'right',
            width: 50,
            render: (v, r) => (
                <div>
                    <Button type="link" onClick={() => {
                        form2.resetFields()
                        form2.setFieldValue("uid", r.id)
                        setOpen(true)
                    }}>开通vip</Button>
                </div>
            )
        }
    ];

    return (
        <div>
            <Modal
                open={open}
                forceRender
                width={200}
                onCancel={() => setOpen(false)}
                onOk={async () => {
                    try {
                        const values = await form2.validateFields()
                        await http.post(`${apis.VIP_OPEN}?uid=${values.uid}&year=${values.year}&month=${values.month}&day=${values.day}`, {})
                        await loadData(search)
                        setOpen(false)
                        message.success("操作成功")
                    } catch (error) {
                        message.success("操作失败")
                    }
                }}
            >
                <Form form={form2}>
                    <Form.Item hidden label="uid" name="uid" initialValue={0}>
                        <InputNumber placeholder="请输入" min={0} />
                    </Form.Item>
                    <Form.Item label="年" name="year" initialValue={0}>
                        <InputNumber placeholder="请输入" min={0} />
                    </Form.Item>
                    <Form.Item label="月" name="month" initialValue={0}>
                        <InputNumber placeholder="请输入" min={0} />
                    </Form.Item>
                    <Form.Item label="日" name="day" initialValue={0}>
                        <InputNumber placeholder="请输入" min={0} />
                    </Form.Item>
                </Form>
            </Modal>
            <span style={{ display: "none" }}>{update}</span>
            <Form autoComplete="off" layout="inline" onFinish={async (val) => {
                const cpSearch = cloneDeep(search)
                cpSearch.page = 1
                cpSearch.email = val.email
                setSearch(cpSearch)
                setLoading(true)
                await loadData(cpSearch)
                setLoading(false)
            }}>
                <Form.Item name="email">
                    <Input allowClear placeholder="邮箱" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">查询</Button>
                </Form.Item>
            </Form>
            <div style={{ height: 24 }}></div>
            <Table
                scroll={{
                    x: "100vw",
                    y: `calc(100vh - 350px - ${document.getElementsByClassName("ant-table-thead")[0]?.clientHeight}px)`
                }}
                rowKey={`id`}
                columns={columns}
                loading={loading}
                dataSource={response.list || []}
                pagination={{
                    pageSize: response.page_size,
                    total: response.total,
                    showSizeChanger: false,
                    onChange: async (page) => {
                        console.log(page)
                        const cpSearch = cloneDeep(search)
                        cpSearch.page = page
                        setSearch(cpSearch)
                        setLoading(true)
                        await loadData(cpSearch)
                        setLoading(false)
                    }
                }} />
        </div>
    )
}

export default Index